define("frontend/instance-initializers/fullheight", ["exports", "frontend/services/fullheight"], function (_exports, _fullheight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('service:fullheight', _fullheight.default);
    application.inject('controller:application', 'fullheight', 'service:fullheight');
  }

  var _default = {
    initialize,
    name: 'fullheight'
  };
  _exports.default = _default;
});