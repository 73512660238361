define("frontend/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: function (tokens) {
      return tokens.reverse().join(' | ') + ' | Media Pop';
    }
  });

  _exports.default = _default;
});