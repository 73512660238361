define("frontend/templates/components/typed-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h80PDMbM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"small\"],[9],[0,\"We \"],[7,\"span\"],[11,\"class\",\"hometype\"],[9],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/typed-text.hbs"
    }
  });

  _exports.default = _default;
});