define("frontend/routes/website/our-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Portfolio',

    model() {
      const portfolios = this.store.findAll('portfolio');
      return portfolios.then(portfolios => {
        return {
          portfolios: portfolios.sortBy('order')
        };
      });
    },

    actions: {
      didTransition() {
        this._super(...arguments);

        this.controllerFor('website.our-work').set('ourWork', true);
      }

    }
  });

  _exports.default = _default;
});