define("frontend/models/portfolio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    client: _emberData.default.belongsTo('client', {
      async: true
    }),
    slug: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    description_after_quote: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    quote: _emberData.default.attr('string'),

    init() {
      this._super(...arguments); //@todo This could be refactored into a helper.


      this.tagOrdering = ['order'];
    },

    languages: _emberData.default.hasMany('tag'),
    languagesSorted: Ember.computed.sort('languages', 'tagOrdering'),
    langaugesValues: Ember.computed.mapBy('languagesSorted', 'value'),
    languagesDisplay: Ember.computed('langaugesValues', function () {
      return this.langaugesValues.join(', ');
    }),
    scopes: _emberData.default.hasMany('tag'),
    scopesSorted: Ember.computed.sort('scopes', 'tagOrdering'),
    scopesValues: Ember.computed.mapBy('scopesSorted', 'value'),
    scopesDisplay: Ember.computed('scopesValues', function () {
      return this.scopesValues.join(', ');
    }),
    image: _emberData.default.attr('string'),
    imageStyle: Ember.computed('image', function () {
      return "background-image: url('" + this.image + "');";
    }),
    small_image: _emberData.default.attr('string'),
    smallImageStyle: Ember.computed('small_image', function () {
      return "background-image: url('" + this.small_image + "');";
    }),
    background_image: _emberData.default.attr('string'),
    backgroundImageStyle: Ember.computed('background_image', function () {
      return "background-image: url('" + this.background_image + "');";
    }),
    mobile_image: _emberData.default.attr('string'),
    mobileImageStyle: Ember.computed('mobile_image', function () {
      return "background-image: url('" + this.mobile_image + "');";
    }),
    imageClass: Ember.computed('image', 'background_image', 'mobile_image', function () {
      if (this.image && this.mobile_image) {
        return 'desktopphone';
      }

      if (this.image) {
        return 'desktop';
      }

      if (this.mobile_image) {
        return 'mobile';
      }

      return '';
    })
  });

  _exports.default = _default;
});