define("frontend/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: "Take Care of Your Web, Mobile and Digital Needs",

    model() {
      return this.store.findAll('home-typing-text');
    }

  });

  _exports.default = _default;
});