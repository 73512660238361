define("frontend/controllers/website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    menuOpen: false,
    actions: {
      toggleMenu() {
        this.set("menuOpen", !this.menuOpen); // @todo This should be a service instead of reaching across the DOM.

        document.querySelector(".page").style.setProperty("overflow", this.menuOpen ? "hidden" : "visible");
      }

    }
  });

  _exports.default = _default;
});