define("frontend/controllers/website/contact-us", ["exports", "fetch", "frontend/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: "",
    actions: {
      submitEmail: function () {
        return (0, _fetch.default)(`${_environment.default.APP.API_HOST}/api/register-email`, {
          headers: {
            "Content-Type": 'application/json; charset=utf-8'
          },
          method: 'POST',
          body: JSON.stringify({
            email: this.email
          })
        }).then(() => {
          // This should only happen if we receive a 200
          this.set('email', '');
          alert('Thank you. We will contact you soon');
          this.set('showErrors', false);
        });
      }
    }
  });

  _exports.default = _default;
});