define("frontend/adapters/application", ["exports", "frontend/adapters/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    addTrailingSlashes: false,
    pathForType: function (type) {
      return Ember.String.pluralize(type) + '.json';
    }
  });

  _exports.default = _default;
});