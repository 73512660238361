define("frontend/mixins/send-after-render-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      didTransition() {
        Ember.run.schedule("afterRender", this, function () {
          this.controller.send("afterRenderAction");
        });
      }

    }
  });

  _exports.default = _default;
});