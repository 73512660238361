define("frontend/templates/components/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCxhJPkr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"textarea\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"textarea\",null,[[\"focus-out\",\"value\",\"class\",\"id\",\"rows\"],[[27,\"action\",[[22,0,[]],\"showErrors\"],null],[23,[\"value\"]],\"form-control\",\"field4\",\"4\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"focus-out\",\"type\",\"value\",\"placeholder\",\"class\"],[[27,\"action\",[[22,0,[]],\"showErrors\"],null],[23,[\"type\"]],[23,[\"value\"]],[23,[\"placeholder\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,[\"showErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"form-error\"],[9],[1,[21,\"errors\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/validated-input.hbs"
    }
  });

  _exports.default = _default;
});