define("frontend/controllers/website/our-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ourWork: true,
    menuOpen: false,
    portfolio: null,
    actions: {
      toggleMenu: function () {
        this.set('menuOpen', !this.menuOpen);
      }
    }
  });

  _exports.default = _default;
});