define("frontend/router", ["exports", "frontend/config/environment", "ember-router-scroll"], function (_exports, _environment, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    headData: Ember.inject.service(),

    setTitle(title) {
      this.headData.set('title', title);
    },

    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition() {
      this._super(...arguments);

      this._trackPage();
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        // https://github.com/poteto/ember-metrics/issues/88#issuecomment-223757653
        if (typeof FastBoot === 'undefined') {
          const page = this.url;
          const title = this.getWithDefault('currentRouteName', 'unknown');
          this.metrics.trackPage({
            page,
            title
          });
        }
      });
    }

  });
  Router.map(function () {
    this.route('website', function () {
      this.route('our-work', function () {
        this.route('item', {
          path: ':portfolio_id'
        });
      });
      this.route('about-us');
      this.route('contact-us');
    });
  });
  var _default = Router;
  _exports.default = _default;
});