define("frontend/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = uppercase;
  _exports.default = void 0;

  function uppercase(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(uppercase);

  _exports.default = _default;
});