define("frontend/components/typed-text", ["exports", "typed.js"], function (_exports, _typed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    texts: Ember.computed.mapBy('model', 'value'),

    didInsertElement() {
      this._super(...arguments); // @todo This could be a standalone component.


      this.set('typed', new _typed.default(".hometype", {
        strings: this.texts,
        typeSpeed: 0,
        startDelay: 600,
        backDelay: 1800
      }));
    },

    willDestroyElement() {
      this.typed.destroy();
    }

  });

  _exports.default = _default;
});