define("frontend/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lbEwsIko",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page\"],[11,\"id\",\"homepage\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"homepage__inner\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"homepage__headline\"],[9],[0,\"\\n        \"],[7,\"h1\"],[9],[0,\"\\n          \"],[7,\"img\"],[11,\"src\",\"/img/logo.svg\"],[11,\"alt\",\"MediaPop\"],[9],[10],[0,\"\\n          \"],[5,\"typed-text\",[],[[\"@model\"],[[21,\"model\"]]]],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"span\"],[9],[4,\"link-to\",[\"website.our-work\"],[[\"class\"],[\"btn\"]],{\"statements\":[[0,\"See our work\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"homepage__contact\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"mailto:sales@mediapopinc.com\"],[9],[7,\"i\"],[11,\"class\",\"ion-email\"],[9],[10],[0,\" sales@mediapopinc.com \"],[10],[0,\"\\n        \"],[7,\"span\"],[9],[7,\"i\"],[11,\"class\",\"ion-location\"],[9],[10],[0,\"Singapore\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"unless\",[[27,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"blur fullheight\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"videobg\"],[9],[0,\"\\n    \"],[7,\"video\"],[11,\"autoplay\",\"\"],[11,\"muted\",\"true\"],[11,\"playsinline\",\"\"],[11,\"loop\",\"\"],[11,\"id\",\"player\"],[11,\"src\",\"img/bgvideo.mp4\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/index.hbs"
    }
  });

  _exports.default = _default;
});