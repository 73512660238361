define("frontend/routes/website/our-work/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: function (model) {
      return model.get('name');
    },

    model(params) {
      let promisePortfolio = this.store.queryRecord('portfolio', {
        'slug': params.portfolio_id
      });
      this.controllerFor('website.our-work').set('portfolio', promisePortfolio);
      return promisePortfolio;
    },

    actions: {
      didTransition() {
        this.controllerFor('website.our-work').set('ourWork', false);
        this.controllerFor('website.our-work').set('menuOpen', false);
      }

    }
  });

  _exports.default = _default;
});