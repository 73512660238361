define("frontend/routes/website/about-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'About Us',

    model() {
      const clients = this.store.findAll('client', {
        reload: true
      });
      return Ember.RSVP.hash({
        expertises: this.store.findAll('expertise'),
        clients: clients.then(clients => {
          return {
            clients: clients.sortBy('order'),
            firstClient: clients.get('firstObject')
          };
        })
      });
    } // actions: {
    //   didTransition() {
    //     this._super(...arguments);
    //     this.controllerFor('website.our-work').set('ourWork', true);
    //
    //     Ember.run.schedule("afterRender",this,function() {
    //       this.controllerFor('website.about-us').send("afterRenderAction");
    //     });
    //   }
    // }


  });

  _exports.default = _default;
});